import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const Image = (props) => {
    const {imageName, className, style} = props
    if (!!imageName && !!imageName?.childImageSharp) {
        return (
            <>
                <GatsbyImage
                    image={getImage(imageName)}
                    key={getImage(imageName)}
                    className={className}
                    style={style}
                    alt="image"
                />
            </>
        );
    } else if (!!imageName?.childImageSharp) {
        return (
            <>
                <GatsbyImage
                    image={getImage(imageName)}
                    key={getImage(imageName)}
                    className={className}
                    style={style}
                    alt="image"
                />
            </>
        );
        // for Netlify CMS
    } else if (imageName) {
        return (
            <>
                <img src={imageName} className={`max-w-[100%] ${className}`} style={style} alt='' />
            </>
        )
    } else {
        return null
    }

    // return (
    //     <>
    //         <GatsbyImage
    //             image={getImage(imageName)}
    //             key={getImage(imageName)}
    //             className={className}
    //             style={style}
    //             alt="image"
    //         />
    //     </>
    // );
};

Image.propTypes = {
    imageName: PropTypes.objectOf(PropTypes.any),
    className: PropTypes.string,
    style: PropTypes.objectOf(PropTypes.any),
};
Image.defaultProps = {
    imageName: {},
    className: '',
    style: {},
};

export default Image;