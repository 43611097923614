/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function SEO({ description, lang, meta, title, image, robotsData }) {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                    }
                }
            }
        `
    );

    const [documentTrue, setDocumentTrue] = useState(false);
    const metaDescription = description || site.siteMetadata.description;
    const defaultTitle = site.siteMetadata.title || "";
    useEffect(() => {
        if (typeof document !== `undefined`) {
            setDocumentTrue(true);
        }
    });

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
            meta={[
                {
                    name: `description`,
                    content: description,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: description,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    property: `og:image`,
                    content: image,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata.author || ``,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: description,
                },
                {
                    name: `twitter:image`,
                    content: image,
                },
                {
                    name: `robots`,
                    content: robotsData,
                },
                {
                    name: "facebook-domain-verification",
                    content: "fqr2cgbq893lt2kuvppbkwh1h9zldj",
                }
            ].concat(meta)}
        >
            {/* <link rel="stylesheet" href="https://use.typekit.net/yps7tid.css" async /> */}
            {documentTrue && document.readyState === "complete" && (
                <script
                    id="ze-snippet"
                    src="https://static.zdassets.com/ekr/snippet.js?key=812fbdeb-b029-4788-9e84-55520a1b73e5"
                />
            )}
        </Helmet>
    );
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
    image: null,
    robotsData: `index follow`,
};

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
    image: PropTypes.node,
    robotsData: PropTypes.string,
};

export default SEO;
